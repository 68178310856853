const mobileMenu = () => {
/*
	document.addEventListener('turbolinks:load', function() {

		const iconMenu = document.querySelector("#b-menu-icon");
		const closedMenu = document.querySelector("#closedBurgerMenu");
 		const openMenu = document.querySelector(".openMenu");

		iconMenu.addEventListener('click', event => {
			event.preventDefault();
			closedMenu.classList.remove('closedBurgerMenu');
			closedMenu.classList.add('openMenu');
			iconMenu.classList.add('menu-icon-rotate-left');
 		})

	})*/
}

export { mobileMenu }