
const memberCardsTabs = () => {

	document.addEventListener('turbolinks:load', function() {
		// Tabs Nav
		const scripts_tab = document.querySelector("#script-tab-btn");
		const adaptations_tab = document.querySelector("#adaptation-tab-btn");

		// Tabs Cards
		const script_cards = document.querySelector("#script-mc");
		const adaptation_cards = document.querySelector("#adaptation-mc");
		
		if (scripts_tab) {
			scripts_tab.addEventListener('click', event => {
				event.preventDefault();
				adaptation_cards.classList.add('display');
				script_cards.classList.remove('display');
				script_cards.classList.add('member-publications-cards');
				
				scripts_tab.classList.add('active');
				adaptations_tab.classList.remove('active');
			})
		}

		if (adaptations_tab) {
			adaptations_tab.addEventListener('click', event => {
				event.preventDefault();
				adaptation_cards.classList.remove('display');
				script_cards.classList.add('display');
				adaptation_cards.classList.add('member-publications-cards');

				adaptations_tab.classList.add('active');
				scripts_tab.classList.remove('active');
			})
		}	
	})	
}

export { memberCardsTabs }