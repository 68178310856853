const dropdownAvatar = () => {

	document.addEventListener('turbolinks:load', function() {

		const avatarIcon = document.querySelector(".dropdown-avatar-nav");
		const dropdownAvatarMenu = document.querySelector(".dropdown-avatar");
		avatarIcon&&avatarIcon.addEventListener('click', event => {
			event.preventDefault();
			if (dropdownAvatarMenu.style.display === "none") {
			    dropdownAvatarMenu.style.display = "block";
			} else {
			    dropdownAvatarMenu.style.display = "none";
			}
		})
	})
}

export { dropdownAvatar }