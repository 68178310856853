
const userSignup = () => {

	document.addEventListener('turbolinks:load', function() {

		// Input checkbox
		const activeYears0 = document.querySelector("#under-2-years");
		const activeYears2 = document.querySelector("#over-2-years");

		const activeYears0dm = document.querySelector("#under-2-years-dm");
		const activeYears2dm = document.querySelector("#over-2-years-dm");
		// Work Sample Uploader form
		const workSample = document.querySelector(".storyteller-sample");
		const resumeDm = document.querySelector(".resume-sample");

		// Storyteller
		activeYears0&&activeYears0.addEventListener('click', (event) => {
			activeYears0.checked = true;
			workSample.style.display = "block";
			activeYears2.checked = false;
		})

		activeYears2&&activeYears2.addEventListener('click', (event) => {
			activeYears0.checked = false;
			activeYears2.checked = true;
			workSample.style.display = "none";
		})

		// Decision Maker
		activeYears0dm&&activeYears0dm.addEventListener('click', (event) => {
			activeYears0dm.checked = true;
			resumeDm.style.display = "block";
			activeYears2dm.checked = false;
		})

		activeYears2dm&&activeYears2dm.addEventListener('click', (event) => {
			activeYears0dm.checked = false;
			activeYears2dm.checked = true;
			resumeDm.style.display = "none";
		})
	})
}

export { userSignup }