// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs";
window.Rails = Rails;
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
//= require dropzone
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "bootstrap";
import { userSignup } from "../plugin/user-signup"
import { memberCardsTabs } from "../plugin/member-tabs"
import { mobileMenu } from "../plugin/menu"
import { dropdownAvatar } from "../plugin/menu-dropdown-avatar"
// import { speechifyFunction } from "../plugin/speechify.js"
// speechifyFunction()

// import { speechifyScriptsList } from "../plugin/speechifyList.js"
// import "../plugin/speechifyapi.min.mjs"
if(window.location.pathname === '/'){
  userSignup();
};
memberCardsTabs();
mobileMenu();
dropdownAvatar();
document.addEventListener('turbolinks:load', () => {
  $("#is_excellent").on("change", e => {
    $(e.currentTarget).closest("form").trigger("submit")
  })
  $(".approve-script").on("click", e => {
    e.preventDefault()
    let target = e.currentTarget
    let id = $(target).data("id")
    let is_excellent = $(target).closest("tr").find("[name='is_excellent']").is(":checked")
    $.post('/scripts/'+id+'/activate', { is_excellent }).then(response => {
      window.location.replace('/approvals/scripts')
    })
  })

});
if(window.location.href.endsWith("/scripts")){
  // $(document).on('turbolinks:load', function() {
  //   speechifyScriptsList()
  // });
    // Called once after the initial page has loaded
    // document.addEventListener(
    //     'turbolinks:load',
    //     () => speechifyScriptsList(),
    //     {
    //       once: true,
    //     },
    //   );
    //   // Called after every non-initial page load
    //   document.addEventListener('turbolinks:render', () =>
    //     speechifyScriptsList(),
    //   );
}
if(window.location.href.indexOf("audios") != -1){
  $(document).on('turbolinks:load', function() {
    // your setup code here...
    // speechifyFunction()
  });
//   // Called once after the initial page has loaded
//   document.addEventListener(
//       'turbolinks:load',
//       () => speechifyFunction(),
//       {
//         once: true,
//       },
//     );
//     // Called after every non-initial page load
//     document.addEventListener('turbolinks:render', () =>
//       speechifyFunction(),
//     );
}
